<template>
  <v-container wrap>
    <v-btn
      :to="{name: 'admin:candidate:applications', params: {uuid: $route.params.uuid}}"
      color="warning"
    >
      <v-icon left>
        undo
      </v-icon>
      Volver
    </v-btn>
    <v-data-table
      :headers="headers"
      :items="applications"
      hide-actions
      :pagination.sync="pagination"
    >
      <template
        slot="headers"
      >
        <tr>
          <th
            v-for="header in headers"
            :key="header.text"
            align="left"
            style="font-size: small; font-weight: bold"
          >
            {{ header.text }}
          </th>
        </tr>
      </template>
      <template
        slot="items"
        slot-scope="props"
      >
        <td>{{ props.item.offer.company }} - {{ props.item.offer.position }} </td>
        <td>{{ states[props.item.recent_status.status] }}</td>
        <td>{{ props.item.recent_status.comment }}</td>
        <td>{{ formatDate(new Date(props.item.recent_status.created_on)) }}</td>
        <td>{{ props.item.offer.admin }}</td>
        <td>
          <v-btn
            :href="props.item.report_file"
            target="_blank"
            :disabled="!props.item.report_file"
          >
            <v-icon left>
              cloud_download
            </v-icon>
            <template v-if="props.item.report_file">
              Descargar
            </template>
            <template v-else>
              No disponible
            </template>
          </v-btn>
        </td>
      </template>
    </v-data-table>
    <v-layout
      v-if="applications.length > 0"
      justify-center
      mt-3
    >
      <v-flex xs4>
        <div class="text-xs-center">
          <v-pagination
            v-model="page"
            :length="pages"
            total-visible="5"
          />
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import OHCStateMixin from '@/components/mixins/OHCStateMixin.vue';

export default {
  name: 'OHCHistory',
  mixins: [JWTConfigMixin, OHCStateMixin],
  data() {
    return {
      headers: [
        {
          text: 'Oferta',
        },
        {
          text: 'Estado',
        },
        {
          text: 'Comentario',
        },
        {
          text: 'Fecha del cambio',
        },
        {
          text: 'Analista',
        },
        {
          text: 'Informe de evaluación',
        },
      ],
      applications: [],
      pagination: {
        descending: true,
        rowsPerPage: 10,
        page: 1,
      },
      page: 1,
      pages: 1,
      nextPage: '',
      prevPage: '',
    };
  },
  watch: {
    page(newVal, oldVal) {
      let pageEndpoint = '';
      if (Math.abs(newVal - oldVal) > 1) {
        pageEndpoint = `${this.$store.state.endpoints.offers.admin.getOHCStates(this.$route.params.uuid)}
        ?page=${newVal}`;
      } else if (newVal > oldVal) {
        pageEndpoint = this.nextPage;
      } else {
        pageEndpoint = this.prevPage;
      }
      this.turnPage(pageEndpoint);
    },
  },
  mounted() {
    this.getHistory();
  },
  methods: {
    getHistory() {
      const endpoint = this.$store.state.endpoints.offers.admin.getOHCHistory(
        this.$route.params.uuid,
      );
      axios.get(endpoint, this.jwtConfig)
        .then(response => {
          this.applications = response.data.results;
          this.nextPage = response.data.next;
          this.prevPage = response.data.previous;
          this.pages = Math.ceil(response.data.count / response.data.results.length);
          this.setPaginator();
        });
    },
    setPaginator() {
      this.pagination.page = this.page;
      this.pagination.rowsPerPage = this.applications.length;
    },
    turnPage(pageLink) {
      if (!pageLink) {
        return;
      }
      this.$store.commit('setLoading');
      axios.get(pageLink, this.jwtConfig)
        .then(response => {
          this.nextPage = response.data.next;
          this.prevPage = response.data.previous;
          this.applications = response.data.results;
          this.$store.commit('unsetLoading');
          this.setPaginator();
        });
    },
    formatDate(date) {
      const dateString = `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${
        `0${date.getDate()}`.slice(-2)}`;
      const timeString = `${`0${date.getHours()}`.slice(-2)}:${`0${date.getMinutes()}`.slice(-2)}:${
        `${date.getSeconds()}`.slice(-2)}`;
      return `${dateString} ${timeString}`;
    },
  },
};
</script>

<style scoped>

</style>
